<template>
  <div class="container">
    <!-- <img src="../assets/header.png" :alt="'logo'" class="img-fluid" /> -->
    <h2 class="text-center">Weekly Check In Form</h2>
    <p class="text-center">
      By filling in this form, you are confidentially sending me your progress
      from this week. Be sure to give me thorough details on your week, so I can
      make changes to your program if necessary.
    </p>
    <form ref="checkinform" class="form" @submit.prevent="submitForm">
      <div
        v-if="isLoading"
        class="d-flex align-items-center justify-content-center p-5"
      >
        <div
          class="spinner-border"
          role="status"
          style="width: 3rem; height: 3rem"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div v-show="false" class="form-group text-left">
          <label for="email">Email</label>
          <input
            id="email"
            v-model="email"
            type="email"
            class="form-control"
            placeholder="Enter your email"
            required
          />
        </div>
        <div v-show="false" class="form-group text-left">
          <label for="first-name">First Name</label>
          <material-input
            id="first-name"
            v-model="name"
            type="text"
            class="form-control"
            required
          />
        </div>
        <div class="form-group text-left">
          <label for="accomplished-goals"
            >How do you <strong>FEEL</strong> about your accomplished goals over
            the last 7 days? - Where did you succeed?</label
          >
          <material-textarea
            id="accomplished-goals"
            v-model="accomplishedGoals"
            class="form-control"
            :is-required="true"
          ></material-textarea>
        </div>
        <div class="form-group text-left">
          <label for="obstacles-faced"
            >What do you <strong>BELIEVE</strong> to be the obstacles you faced?
            What took priority?</label
          >
          <material-textarea
            id="obstacles-faced"
            v-model="obstaclesFaced"
            class="form-control"
            :is-required="true"
          ></material-textarea>
        </div>
        <div class="form-group text-left">
          <label for="activity"
            >How active have you been this week? (please add #workouts, average
            step count or minutes walked)</label
          >
          <material-input
            id="activity"
            v-model="activity"
            type="text"
            class="form-control"
            :placeholder="'no. workouts, average step count or minutes walked'"
            :is-required="true"
          />
        </div>
        <div class="form-group text-left">
          <label for="energy"
            >How has your <strong>ENERGY</strong> been this week?</label
          >
          <input
            id="energy"
            v-model="energy"
            type="range"
            min="1"
            max="5"
            class="form-control"
            required
            @touchstart="focusNextInput"
          />
          <p>{{ energyTranslation }}</p>
        </div>
        <div class="form-group text-left">
          <label for="stress"
            >How much <strong>STRESS</strong> are you currently dealing
            with?</label
          >
          <input
            id="stress"
            v-model="stress"
            type="range"
            min="1"
            max="5"
            class="form-control"
            required
            @touchstart="focusNextInput"
          />
          <p>{{ stressTranslation }}</p>
        </div>
        <div class="form-group text-left">
          <label for="hunger"
            >How would you rate your <strong>HUNGER</strong> on a daily
            basis?</label
          >
          <input
            id="hunger"
            v-model="hunger"
            type="range"
            min="1"
            max="5"
            class="form-control"
            required
            @touchstart="focusNextInput"
          />
          <p>{{ hungerTranslation }}</p>
        </div>
        <div class="form-group text-left">
          <label for="sleep"
            >How would you rate your <strong>SLEEP</strong> over the past
            week?</label
          >
          <input
            id="sleep"
            v-model="sleep"
            type="range"
            min="1"
            max="5"
            class="form-control"
            required
            @touchstart="focusNextInput"
          />
          <p>{{ sleepTranslation }}</p>
        </div>
        <div class="form-group text-left">
          <label for="cravings"
            >Any days you experience cravings or emotional eating?</label
          >
          <input
            id="cravings"
            v-model="cravings"
            type="range"
            min="0"
            max="7"
            class="form-control"
            required
            @touchstart="focusNextInput"
          />
          <p>{{ cravings }} days</p>
        </div>
        <div class="form-group text-left">
          <label for="digestion"
            >How would you rate your <strong>DIGESTION</strong>?</label
          >
          <input
            id="digestion"
            v-model="digestion"
            type="range"
            min="1"
            max="5"
            class="form-control"
            required
            @touchstart="focusNextInput"
          />
          <p>{{ digestionTranslation }}</p>
        </div>
        <div class="form-group text-left">
          <label for="mental-health"
            >How would you rate your mental health?</label
          >
          <input
            id="mental-health"
            v-model="mentalHealth"
            type="range"
            min="1"
            max="5"
            class="form-control"
            required
            @touchstart="focusNextInput"
          />
          <p>{{ mentalHealthTranslation }}</p>
        </div>
        <div class="form-group text-left">
          <label for="waist-measurement"
            >Please add your <strong>WAIST</strong> measurement (cm or
            inches)</label
          >
          <material-input
            id="waist-measurement"
            v-model="waistMeasurement"
            type="number"
            class="form-control"
            step="0.1"
            min="1"
            :is-required="true"
          />
        </div>
        <div class="form-group text-left">
          <label for="hips-measurement"
            >Please add your <strong>HIPS</strong> measurement (cm or
            inches)</label
          >
          <material-input
            id="hips-measurement"
            v-model="hipsMeasurement"
            type="number"
            class="form-control"
            step="0.1"
            min="1"
            :is-required="true"
          />
        </div>
        <div class="form-group text-left">
          <label for="weight">Today's weight (if not weighing, type 0)</label>
          <material-input
            id="weight"
            v-model="weight"
            type="number"
            class="form-control"
            step="0.1"
            min="1"
            :is-required="true"
          />
        </div>
        <div class="form-group text-left">
          <label for="upcoming-events"
            >Any upcoming events or special occasions?</label
          >
          <material-textarea
            id="upcoming-events"
            v-model="upcomingEvents"
            class="form-control"
            required
          ></material-textarea>
        </div>
      </div>
      <material-button
        type="submit"
        :disabled="isLoading"
        class="btn btn-primary btn-lg"
        value="Submit"
        >Submit</material-button
      >
    </form>
  </div>
</template>

<script>
import {
  getPreviousClientCheckIns,
  submitForm,
} from "../../data/checkin/checkin.js";
import { sendGridEmail, sendSelfCheckInEmail } from "../../data/email/email.js";
import MaterialInput from "../../components/MaterialInput.vue";
import MaterialTextarea from "../../components/MaterialTextarea.vue";
import MaterialButton from "../../components/MaterialButton.vue";
import supabase from "../../data/createClient";
import { mapState } from "vuex";
import Nutrition from "../../data/nutrition/nutrition.js";
import { logError } from "../../errors/rollbar";
import UserSettings from "../../data/user/userSettings.js";

export default {
  name: "FormView",
  components: {
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
  },
  data() {
    return {
      isLoading: false,
      email: "",
      firstName: "",
      accomplishedGoals: "",
      obstaclesFaced: "",
      activity: "",
      energy: 5,
      stress: 1,
      hunger: 1,
      sleep: 5,
      cravings: 0,
      digestion: 5,
      mentalHealth: 5,
      waistMeasurement: null,
      hipsMeasurement: null,
      weight: null,
      upcomingEvents: "",
      notes: "",
    };
  },
  computed: {
    energyTranslation() {
      if (this.energy <= 2) {
        return "Low";
      } else if (this.energy === "3") {
        return "Neutral";
      } else {
        return "High";
      }
    },
    stressTranslation() {
      if (this.stress <= 2) {
        return "Low";
      } else if (this.stress === "3") {
        return "Neutral";
      } else {
        return "High";
      }
    },
    hungerTranslation() {
      if (this.hunger <= 2) {
        return "Low";
      } else if (this.hunger === "3") {
        return "Neutral";
      } else {
        return "High";
      }
    },
    sleepTranslation() {
      if (this.sleep <= 2) {
        return "Low";
      } else if (this.sleep === "3") {
        return "Neutral";
      } else {
        return "High";
      }
    },
    digestionTranslation() {
      if (this.digestion <= 2) {
        return "Low";
      } else if (this.digestion === "3") {
        return "Neutral";
      } else {
        return "High";
      }
    },
    mentalHealthTranslation() {
      if (this.mentalHealth <= 2) {
        return "Low";
      } else if (this.mentalHealth === "3") {
        return "Neutral";
      } else {
        return "High";
      }
    },
    ...mapState(["name"]),
  },
  async mounted() {
    const { data } = await supabase.auth.getSession();
    if (!data || !data.session || !data.session.user) return;
    const user = data.session.user;
    if (!user) return;
    this.email = user.email;
  },
  methods: {
    async submitForm() {
      this.isLoading = true;

      if (!this.$refs.checkinform.checkValidity()) {
        this.$swal({
          title: "Error!",
          text: "Please fill in all required fields",
          icon: "warning",
          confirmButtonText: "Ok",
        });
        this.isLoading = false;
        return;
      }

      const todaysDate = new Date().toISOString().split("T")[0];
      const userId = await UserSettings.getUserId();

      // select the users current usermetric for the day
      const {data: currentData, error: currentError} = await supabase.from("usermetrics")
      .select("*")
      .eq("date", todaysDate);

      if (currentError) {
        logError(currentError);
      } 

      if (currentData && currentData.length > 0) {
        const { data: weightData, error: weightError } = await supabase.from("usermetrics").upsert(
        {
          id: currentData[0].id,
          userid: userId,
          date: todaysDate,
          weight: parseFloat(this.weight),
          complete: true,
        },
      ).select();

      if (weightError) {
        logError(weightError);
      } else {
        console.log("Weight data", weightData);
      }
      } else {
        const { error: weightError } = await supabase.from("usermetrics").insert(
        {
          userid: userId,
          date: todaysDate,
          weight: parseFloat(this.weight),
          complete: true,
        },
      ).select();

      if (weightError) {
        logError(weightError);
      } 
      }      

      const tdeeDetails = await Nutrition.calculateNewTDEEFromCheckIn();

      if (tdeeDetails.error) {
        await this.$swal({
          title: "Error!",
          text: tdeeDetails.message,
          icon: "warning",
          confirmButtonText: "Ok",
        });

        logError(tdeeDetails.message);
      }

      let tdee = 0;

      if (tdeeDetails.pending) {
        tdee = tdeeDetails.newTDEE;
      }

      const userGroup = await UserSettings.userGroup();

      if (userGroup === 4) {
        if (tdeeDetails.pending) {
          const confirmChange = await this.$swal({
            title: "TDEE Change",
            text: `Your maintanence calories have changed to ${tdeeDetails.newTDEE}. Would you like to update your macros to reflect this change?`,
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Yes please",
            cancelButtonText: "No thanks",
          });

          if (confirmChange.isConfirmed) {
            const update = await Nutrition.calculateNewTDEEFromCheckIn(true);

            if (update.error) {
              logError(update.message);
              await this.$swal({
                title: "Error",
                text: "There was an error updating your nutrition.",
                icon: "error",
                confirmButtonText: "Ok",
              });
              this.isLoading = false;
              return;
            } else {
              await this.$swal({
                title: "Success!",
                text: "Your nutrition has been updated.",
                icon: "success",
                confirmButtonText: "Ok",
              });
            }
          }
        }
      }

      const formData = {
        email: this.email,
        firstName: this.name,
        accomplishedGoals: this.accomplishedGoals,
        obstaclesFaced: this.obstaclesFaced,
        activity: this.activity,
        energy: this.energy,
        energyNotes: this.energyNotes,
        stress: this.stress,
        hunger: this.hunger,
        sleep: this.sleep,
        cravings: this.cravings,
        digestion: this.digestion,
        mentalHealth: this.mentalHealth,
        waistMeasurement: this.waistMeasurement,
        hipsMeasurement: this.hipsMeasurement,
        weight: this.weight,
        upcomingEvents: this.upcomingEvents,
        notes: this.notes,
        tdee: tdee,
      };

      const formResponse = await submitForm(formData);

      if (formResponse.error) {
        this.$swal({
          title: "Error!",
          text: "There was an error submitting your checkin",
          icon: "error",
          confirmButtonText: "Ok",
        });
        this.$rollbar.error(formResponse.error);
        this.isLoading = false;
        return;
      }

      const previousCheckIns = await getPreviousClientCheckIns(this.email);

      if (previousCheckIns.error) {
        this.$swal({
          title: "Error!",
          text: "There was an error submitting your checkin",
          icon: "error",
          confirmButtonText: "Ok",
        });
        this.isLoading = false;
        return;
      }

      //only send email to coach if user is a client.
      if (userGroup === 2) {
        const emailResponse = await sendGridEmail(
          formData,
          `New Client Checkin Submitted by ${formData.firstName}`,
          previousCheckIns
        );

        if (!emailResponse) {
          this.$swal({
            title: "Error!",
            text: "There was an error submitting your checkin",
            icon: "error",
            confirmButtonText: "Ok",
          });
          this.isLoading = false;
          return;
        }
      } else if (userGroup === 4) {
        const emailResponse = await sendSelfCheckInEmail(
          formData,
          `Here is your check-in response ${formData.firstName}`,
          previousCheckIns
        );

        if (!emailResponse) {
          this.$swal({
            title: "Error!",
            text: "There was an error submitting your checkin",
            icon: "error",
            confirmButtonText: "Ok",
          });
          this.isLoading = false;
          return;
        }
      }

      this.$swal({
        title: "Success!",
        text: "Your checkin has been submitted",
        icon: "success",
        confirmButtonText: "Ok",
      });

      this.isLoading = false;

      this.$router.push("/");
    },
    focusNextInput() {
      document.getElementById("activity").blur();
      document.getElementById("waist-measurement").blur();
    },
  },
};
</script>
<style scoped>
.form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn {
  width: 100%;
}

.form-control {
  -webkit-appearance: auto;
}
</style>
